<template>
  <div>
    <v-dialog :max-width="maxWidth" v-model="drawer" scrollable
          @update:model-value="closeModal(true)"
        >
            <v-card>
              <!-- HEADER -->
              <v-card-title class="d-flex pt-4 pl-6 border-b">
              <div class="text-h5">
                <!-- TITLE -->
                <span v-if="titleModal" class="mr-3" v-html="titleModal" />
                <!-- CUSTOM TOP BUTTONS -->
                <template v-if="currentKey && !isForm && permissionName && !hideTopButtons">

                    <ModalTopButtons 
                        :isForm="isForm" :permissionName="permissionName" :translationName="translationName" :deleteDisabled="deleteDisabled" :editDisabled="editDisabled" :data="data"
                        @redirectEdit="redirectEdit" @deleteData="$emit('deleteData', $event)" @redirectTable="redirectTable"
                    >
                        <template #customButtons>
                            <slot
                                name="customButtons"
                                :item="data"
                            />
                        </template>
                    </ModalTopButtons>
                    </template>
                </div>
                <v-spacer></v-spacer>
                <!-- CLOSE BUTTON -->
                <v-btn icon variant="text" size="small" @click.prevent="closeModal()" aria-label="Fermer">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text ref="content">
                <!-- CUSTOM TOP BUTTONS -->
                <slot name="customContentTop"></slot>
                <!-- CONTENT -->
                <slot name="content" />
              </v-card-text>
              <!-- ACTIONS -->
              <v-card-actions class="border-t pa-4" v-if="!hideDefaultActions">
                <ModalBottomButtons  
                  :permissionName
                  :isForm="isForm" 
                  :saving="saving" 
                  :editDisabled="editDisabled" 
                  :deleteDisabled="deleteDisabled" 
                  :saveText="saveText" 
                  :customButtonColor="customButtonColor" 
                  :customButtonBlock="customButtonBlock" 
                  :disableSaveButton="disableSaveButton" 
                  :currentKey="currentKey"
                  @closeModal="closeModal" 
                  @save="$emit('save');" 
                  @redirectEdit="redirectEdit"
              />
              </v-card-actions>
            </v-card>
        </v-dialog>
        <template v-if="showCloseMessage">
            <ModalPreventClose @close-modal="closeModal" />
        </template>
  </div>

</template>

<script lang="ts">
    import ModalBase from './Base.vue'
    export default {
        name : "ModalMiddle",
        extends : ModalBase,
        emits: ['save', 'closeModal', 'deleteData'],
      }
</script>
